import api from "../api";
import { AxiosResponse } from "axios";
import { AddServicePayload } from "../../interfaces/AppointmentPatient";

class PatientService {
  deactivate = (appointmentPatientId: string) => {
    let data = {
      active: false,
    };
    return api.patch(`appointment-patients/${appointmentPatientId}/`, data);
  };

  activate = (appointmentPatientId: string) => {
    let data = {
      active: true,
    };
    return api.patch(`appointment-patients/${appointmentPatientId}/`, data);
  };

  addService = (
    appointmentPatientId: string,
    labServiceId: string,
    isFonasa: boolean
  ): Promise<AxiosResponse<AddServicePayload>> => {
    let data = {
      labservice_id: labServiceId,
      is_fonasa: isFonasa,
    };
    return api.post<AddServicePayload>(
      `appointment-patients/${appointmentPatientId}/add_service/`,
      data
    );
  };

  fetchPatientAppointments = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`patients/${id}/appointments/`);
  };

  detailsByIdentifier = (identifier: string): Promise<AxiosResponse<any>> => {
    return api.get(`users/details_by_identifier/`, { params: { identifier } });
  };
}

const patientService = new PatientService();

export default patientService;
